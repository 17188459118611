
/**
 * 券领取的方式
 * @readonly
 * @enum {string}
 */
export const RECEIVED_MODE_ENUM = {
  login: 'login',
  click: 'click',
  forbid: 'forbid',
  notify: 'notify',
}
/**
 * 券的展示样式
 * @readonly
 * @enum {string}
 */
export const SHOW_TYPE_ENUM = {
  Modal: 'Modal',
  Flow: 'Flow',
  Up: 'Up',
  Bottom: 'Bottom',
  AccountFlow: 'AccountFlow'
}
/**
 * 不同类型券的唯一标识 只做提示作用
 * @readonly
 * @enum {string}
 */
export const COUPON_REMARK_ENUM = {
  选品页弹窗券: Symbol('选品页弹窗券'),
  选品页插坑券: Symbol('选品页插坑券'),
  搜索页登录后弹框券: Symbol('搜索页登录后弹框券'),
  搜索页登录后插坑券: Symbol('搜索页登录后插坑券'),
  搜索页未登录插坑券: Symbol('搜索页未登录插坑券'),
  搜索页未登录置顶券: Symbol('搜索页未登录置顶券'),
  搜索页未登录吸底券: Symbol('搜索页未登录吸底券'),
  搜索页账号内可用券: Symbol('搜索页未登录弹窗券'),
}
