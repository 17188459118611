// import httpRequest from 'public/src/services/httpRequest/index.js'
import schttp from 'public/src/services/schttp'

export const getCccProductList = (data = {}, abortController) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/productList/get',
    method: 'POST',
    data,
    signal: abortController?.signal
  })
}


export const getCccProductAtom = (data = {}, headers = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/products/query',
    method: 'POST',
    data,
    headers
  })
}

export const getCccFlashSaleList = (data = {}, headers = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/flashSaleList/get',
    method: 'POST',
    data,
    headers
  })
}
// 不贵组件
export const getCccSuperDealsList = (data = {}, headers = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/superDealsList/get',
    method: 'POST',
    data,
    headers
  })
}

// 获取dailynew组件tab
export const getCccDailyNewDay = (data = {}) => {
  return schttp({
    url: '/api/ccc/dailyNewDay/get',
    method: 'get',
    params: data,
  })
}

// 获取新客组件
export const getCccNewUser = (data = {}) => {
  return schttp({
    url: '/api/ccc/newUser/get',
    method: 'get',
    params: data,
  })
}

// 推荐店铺组件
export const getCccTrendStoreList = (data = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    url: '/api/ccc/trendStoreList/get',
    method: 'POST',
    data
  })
}

// 获取订阅组件手机区号列表
export const getCccCountryList = () => {
  return schttp({
    url: '/api/ccc/countryList/query',
    method: 'get'
  })
}

// 订阅组件发送短信
export const sendCccVerifyCode = (data) => {
  return schttp({
    url: '/api/ccc/sendCccVerifyCode/create',
    method: 'POST',
    data,
  })
}

// 订阅组件提交
export const postCccVerifySub = (data) => {
  return schttp({
    url: '/api/ccc/postCccVerifySub/create',
    method: 'POST',
    data,
  })
}

// 查询订阅列表
export const postCccSubList = (data) => {
  return schttp({
    url: '/api/ccc/postCccSubList/query',
    method: 'POST',
    data,
  })
}

// 查询点赞记录
export const getThumbRecord = (data = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/like/query',
    method: 'POST',
    data,
  })
}
// 点赞
export const thumbReport = (data = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/like/update',
    method: 'POST',
    data,
  })
}
// 取消点赞
export const thumbCancel = (data = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/like/delete',
    method: 'POST',
    data,
  })
}

// 意见收集组件-查询用户提交状态
export const getUserSurveyRecord = (data) => {
  return schttp({
    url: '/api/ccc/userSurveyRecord/get',
    method: 'get',
    params: data,
  })
}

// 意见收集组件提交问卷答案
export const postSurveyCommit = (data) => {
  return schttp({
    url: '/api/ccc/surveyCommit/create',
    method: 'POST',
    data,
  })
}

// pk组件投票
export const postPkVote = (data) => {
  return schttp({
    url: '/api/ccc/PkVote/create',
    method: 'POST',
    data,
  })
}


// 福利组件奖池
export const getWelfareBase = (data) => {
  return schttp({
    url: '/api/ccc/welfare/base',
    method: 'POST',
    data,
  })
}

// 福利组件首页信息
export const getWelfareIndex = (data) => {
  return schttp({
    url: '/api/ccc/welfare/index',
    method: 'POST',
    data: { requestType: 'welfareIndex', ...data },
  })
}

// 福利组件签到
export const postWelfareStatus = (data) => {
  return schttp({
    url: '/api/ccc/welfare/update',
    method: 'POST',
    data: { requestType: 'updateWelfareStatus', ...data },
  })
}

// 券预告组件奖池
export const geGrabCouponBase = (data) => {
  return schttp({
    url: '/api/ccc/grab/coupon/base',
    method: 'POST',
    data,
  })
}

// 券预告组件首页信息
export const geGrabCouponIndex = (data) => {
  return schttp({
    url: '/api/ccc/grab/coupon/index',
    method: 'POST',
    data: { requestType: 'GrabCouponIndex', ...data },
  })
}

// 券预告组件签到
export const postGrabCouponStatus = (data) => {
  return schttp({
    url: '/api/ccc/grab/coupon/update',
    method: 'POST',
    data: { requestType: 'updateGrabCouponStatus', ...data },
  })
}


// 券预告组件订阅
export const postGrabCouponSubscribeStatus = (data) => {
  return schttp({
    url: '/api/ccc/grab/coupon/subscribe/update',
    method: 'POST',
    data: { requestType: 'updateGrabCouponSubscribeStatus', ...data },
  })
}

// 游戏风控
export const getGameRiskCtrl = (data) => {
  return schttp({
    url: '/api/game/common/gameRiskCtrl',
    method: 'POST',
    data,
  })
}

// 商品流
export const getCccRecommendFlowCate = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/recommendFlowCateIdAndName/get',
      method: 'POST',
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 商品流类目详情
export const getCccRecommendFlowCateAndGoods = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/getRecommendFlowCateAndGoods/get',
      method: 'POST',
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 商品流
export const getCccRecommendFlowProducts = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/recommendFlowList/get',
      method: 'POST',
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 商品流
export const getCccRecommendFlowCompose = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/recommendFlowCompose/get',
      method: 'POST',
      data,
      timeout: 20000, // 20s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 推荐商品流配置
export const getCccRecommendFlowConfig = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/recommendFlowConfig/get',
      method: 'POST',
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// bff推荐商品流
export const getCccRecommendFlowBff = async (data) => {
  try {
    return await schttp({
      method: 'GET',
      url: '/product/recommend/ccc_component_common',
      useBffApi: true,
      params: data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 品牌推荐
export const getCccBrandRecommend = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/brandRecommend/query',
      method: 'POST',
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 品牌推荐
export const getCccCateLink = async (ids) => {
  try {
    return await schttp({
      method: 'POST',
      url: `/api/ccc/cateLink/get`,
      data: { ids }
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}


// 排行榜推荐(包含新品和销量)
export const getCccRankingEntranceRecommendList = async (data = {}, headers = {}) => {
  try {
    if (!Object.values(data).length) return Promise.resolve({
      code: -1,
      msg: 'miss params'
    })
    
    return await schttp({
      url: '/api/ccc/rankingEntranceRecommendList/query',
      method: 'POST',
      data,
      headers
    })
  }catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 列表推荐榜单商品
export const getRankingListProducts = async (data) => {
  try {
    return await schttp({
      method: 'POST',
      url: `/api/rankingList/products/get`,
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 热销排行榜
export const getHotRankList = async (data) => {
  try {
    return await schttp({
      method: 'POST',
      url: `/api/ccc/hotRank/query`,
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request',
      error
    })
  }
}

// 热销排行榜
export const getRankCommon = async (data) => {
  try {
    return await schttp({
      method: 'POST',
      url: `/api/ccc/rank/query`,
      data,
      timeout: 10000, // 10s
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request',
      error
    })
  }
}

// 历史最低价
export const getLowestPrice = async (data) => {
  try {
    return await schttp({
      method: 'POST',
      url: `/api/ccc/lowestPrice/query`,
      data,
      timeout: 10000,
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request',
      error
    })
  }
}

//  ====================== 店铺 Start ======================

// 获取 - 店铺信息
export const getStoreBase = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/base/query',
    params: { requestType: 'storeBase' },
    data
  })
}

// 获取 - 店铺组装数据
export const getStoreCompose = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/base/query',
    params: { requestType: 'storeCompose' },
    data
  })
}

// 更新 - 关注状态
export const updateFollowState = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/follow/update',
    params: { requestType: 'storeFollow' },
    data
  })
}

// 获取 - 关注状态
export const getStoreFollowState = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/follow/get',
    params: { requestType: 'storeFollowState' },
    data
  })
}

// 批量获取 - 关注状态
export const getStoreFollowStateBatch = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/follow/query',
    params: { requestType: 'storeFollowStateBatch' },
    data
  })
}

// 获取 - 店铺商品列表
export const getStoreProductList = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/product/get',
    params: { requestType: 'storeProductList' },
    data
  })
}

// 获取 - 店铺商品列表
export const getStoreProductListBatch = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/store/product/query',
    params: { requestType: 'storeProductListBatch' },
    data
  })
}

//  ====================== 店铺 End ======================

//  ====================== 店铺页组件 Start ======================
// 批量获取 - 单品组件获取商品
export const getSingleProductList = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/singleProduct/query',
    data
  })
}

// 获取店铺活动组件商品数据（选品列表）
export const getShopActivityList = async (data = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/shopActivityList/get',
      method: 'POST',
      data,
    })
  } catch (e) {
    return Promise.resolve({
      code: -1,
      msg: 'shop activity error request'
    })
  }
}
//  ====================== 店铺页组件 End ======================

// 品牌推荐
export const getCccCategoryRecommendDynamic = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/categoryRecommendDynamic/query',
      method: 'POST',
      data
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 店铺品类推荐
export const getRecommendFacadeData = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/storeCategoryRecommend/query',
      method: 'POST',
      data
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}


// 二分之一组件聚合数据
export const getCccHalfList = (data = {}, headers = {}) => {
  if (!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })
  
  return schttp({
    url: '/api/ccc/half/get',
    method: 'POST',
    data,
    headers
  })
}

// 获取场景物料的配置
export const getSceneMaterialConfig = (data) => {
  return schttp({
    url: '/api/ccc/sceneMaterialConfig/get',
    method: 'POST',
    data,
  })
}

//  ====================== 页面全局配置 Start ======================

// 获取 - 页面全局配置（点赞数据、分享奖励数据）
export const getPageConfigBaseData = (data) => {
  return schttp({
    url: '/api/ccc/campaignsPageConfig/base/query',
    method: 'POST',
    data,
    params: { requestType: 'campaignsPageConfigBase' },
  })
}

// 获取 - 页面全局配置（点赞数据）
export const getPageConfigLikeData = (data) => {
  return schttp({
    url: '/api/ccc/campaignsPageConfig/like/get',
    method: 'POST',
    data,
    params: { requestType: 'campaignsPageConfigLikeData' },
  })
}

// 获取 - 页面全局配置（分享奖励数据）
export const getPageConfigShareData = (data) => {
  return schttp({
    url: '/api/ccc/campaignsPageConfig/share/get',
    method: 'POST',
    data,
    params: { requestType: 'campaignsPageConfigShareData' },
  })
}

// 更新 - 页面点赞状态更新
export const updatePageConfigLikeState = (data) => {
  return schttp({
    url: '/api/ccc/campaignsPageConfig/like/update',
    method: 'POST',
    data,
    params: { requestType: 'campaignsPageConfigLike' },
  })
}

// 获取 - 页面分享奖励发奖
export const getPageConfigSharePrize = (data) => {
  const { challenge } = data || {} // 是否需要极验
  return schttp({
    url: `/api/ccc/campaignsPageConfig/${challenge ? 'sharePrizeGeeTest' : 'sharePrize'}/get`,
    method: 'POST',
    data,
    params: { requestType: 'campaignsPageConfigSharePrize' },
  })
}

// 获取 - 页面分享长链接转短链接
export const getPageConfigShortLink = (data) => {
  return schttp({
    url: '/api/ccc/campaignsPageConfig/shortLink/get',
    method: 'POST',
    data,
    params: { requestType: 'campaignsPageConfigShortLink' },
  })
}

// 获取 - 领取积分
export const getPageConfigDialogPoint = (data) => {
  return schttp({
    url: '/api/ccc/campaignsPageConfig/dialog/point/get',
    method: 'POST',
    data,
    params: { requestType: 'campaignsPageConfigClaimDialogPoint' },
  })
}

// 获取积分对应金额
export const getPointExchangePriceApi = (data) => {
  return schttp({
    method: 'GET',
    url: `/api/ccc/campaignsPageConfig/dialog/point/price`,
    params: data
  })
}

// 弹窗业务批量绑定优惠券
export const bindPopCouponApi = (data) => {
  return schttp({
    method: 'POST',
    url: `/api/ccc/campaignsPageConfig/dialog/coupon/bind`,
    data,
    params: { requestType: 'campaignsPageConfigBindPopCoupon' },
  })
}

//  ======================  页面全局配置 End  ======================

//  ======================  品牌馆 - 品牌组件 Start  ======================

// 获取 - 品牌详情ToC
export const getBrandSingleDetail = (data) => {
  return schttp({
    url: '/api/ccc/brandPavilion/detail/get',
    method: 'POST',
    data,
    params: { requestType: 'brandSingleDetail' },
  })
}

// 批量获取 - 品牌详情ToC
export const getBrandPavilionDetail = (data) => {
  return schttp({
    url: '/api/ccc/brandPavilion/detail/query',
    method: 'POST',
    data,
    params: { requestType: 'brandPavilionDetail' },
  })
}

// 批量获取 - 通过品牌code获取选品信息
export const getBrandPavilionSelect = (data) => {
  return schttp({
    url: '/api/ccc/brandPavilion/selectCode/query',
    method: 'POST',
    data,
    params: { requestType: 'brandPavilionSelect' },
  })
}

// 批量获取 - 品牌聚合数据
export const getBrandPavilionCompose = (data) => {
  return schttp({
    url: '/api/ccc/brandPavilion/compose/query',
    method: 'POST',
    data,
    params: { requestType: 'brandPavilionCompose' },
  })
}

// 批量获取 - 品牌-品类轮播聚合数据
export const getCategorySlideCompose = (data) => {
  return schttp({
    url: '/api/ccc/categorySlide/compose/query',
    method: 'POST',
    data,
    params: { requestType: 'categorySlideCompose' },
  })
}

// 查询 - 根据选品id获取 商品总个数 / 上新商品个数
export const getBrandProductNum = (data) => {
  return schttp({
    url: '/api/ccc/brandPavilion/productNum/get',
    method: 'POST',
    data,
    params: { requestType: 'brandProductNum' },
  })
}

// 批量获取 - 品牌聚合数据
export const getBrandPavilionDesc = (data) => {
  return schttp({
    url: '/api/ccc/brandPavilion/desc/query',
    method: 'POST',
    data,
    params: { requestType: 'brandPavilionDesc' },
  })
}

//  ======================  品牌馆 - 品牌组件 End  ======================

// 获取 - 直降节省价列表
export const getFallList = async (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/fallList/get',
    data
  })
}

// 免邮政策组件
export const getCccFreeshippingData = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/freeshipping/query',
      method: 'POST',
      data
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}
// 批量获取 - 品牌详情
export const getBrandWallDetail = (data) => {
  return schttp({
    url: '/api/ccc/brandWall/detail/query',
    method: 'POST',
    data,
    params: { requestType: 'brandPavilionCompose' },
  })
}

// 获取品牌商品列表
export const getBrandProductList = (data, params = {}) => {
  return schttp({
    url: '/api/ccc/brandProductList/query',
    method: 'POST',
    data,
    params,
  })
}

//  ======================  品牌馆 - 字母列表组件 Start  ======================

// 获取 - 第三方品牌推荐品牌列表（可加选：获取前n个品牌聚合数据）
export const getBrandLetterRecommend = (data) => {
  return schttp({
    url: '/api/ccc/brandLetter/recommend/query',
    method: 'POST',
    data,
    params: { requestType: 'brandLetterRecommend' },
  })
}

// 批量获取 - 品牌聚合数据
export const getBrandLetterCompose = (data) => {
  return schttp({
    url: '/api/ccc/brandLetter/compose/query',
    method: 'POST',
    data,
    params: { requestType: 'brandLetterCompose' },
  })
}

//  ======================  品牌馆 - 字母列表组件 End  ======================

// 横滑频道入口组件
export const getCccHorizontalSlideChanelDynamic = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/horizontalSlideChanelDynamic/query',
      method: 'POST',
      data,
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 横滑频道入口二期组件
export const getCccHorizontalSlideChanelDynamicNew = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/horizontalSlideChanelDynamicNew/query',
      method: 'POST',
      data,
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 同时请求多个商品列表
export const getMultiProductList = (data = {}, abortController) => {
  const { productParamsList = [], requestFields = {}, config = {} } = data
  if (!productParamsList.length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  return schttp({
    url: '/api/ccc/multiProductList/get',
    method: 'POST',
    data: {
      productParamsList,
      requestFields,
      config,
    },
    signal: abortController?.signal
  })
}

// 动态组件
export const getCccCodesDynamic = async (data) => {
  try {
    return await schttp({
      url: '/api/ccc/CodesDynamic/query',
      method: 'POST',
      data,
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 获取会员信息
export const getSheinClubData = async () => {
  try {
    return await schttp({
      url: '/api/ccc/sheinClub/get',
      method: 'get',
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 获取 - 设计师组件推荐列表聚合数据
export const getSheinXRecommend = (data = {}, headers = {}) => {
  return schttp({
    url: '/api/ccc/sheinxRecommend/get',
    method: 'GET',
    params: data,
    headers,
  })
}

// 排行榜组件获取单个商品收藏状态
export const getRankSaveStatus = async (data = {}) => {
  const res = await schttp({
    url: '/api/ccc/rank/saveStatus/get',
    method: 'GET',
    params: data,
  }) || {}
  return res?.info?.result
}

//  ====================== sheinx 投票组件 Start ======================
// 获取 - 组装投票详情
export const getSheinxVoteCompose = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/sheinxVote/detail/query',
    params: { requestType: 'sheinxVoteCompose' },
    data
  })
}

// 获取 - 用户投票信息
export const getSheinxVoteUser = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/sheinxVote/userInfo/get',
    params: { requestType: 'sheinxVoteUser' },
    data
  })
}

// 更新 - 投票
export const sheinxVoteReport = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/sheinxVote/report/update',
    params: { requestType: 'sheinxVoteReport' },
    data
  })
}
//  ====================== sheinx 投票组件 End ======================
// 查询三档券包信息
export const getCouponPackageInfo = async (data = {}, headers = {}) => {
  try {
    if (!Object.values(data).length) return Promise.resolve({
      code: -1,
      msg: 'miss params'
    })
    return await schttp({
      url: '/api/ccc/couponPackage/query',
      method: 'POST',
      data,
      headers
    })
  }catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 查询推客券券包信息
export const getNewuserCouponPackageInfo = async (data = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/newuserCouponPackage/query',
      method: 'POST',
      params: { requestType: 'couponList' },
      data,
    })
  }catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

// 绑定推客券券包信息
export const bindNewuserCouponPackageInfo = async (data = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/newuserCouponPackage/update',
      method: 'POST',
      params: { requestType: 'receiveCoupons' },
      data,
    })
  }catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

//  ======================  专题抽奖相关 Start  ======================
// 查询抽奖结果
export const getLotteryResult = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/lotteryRecord/query',
    params: { requestType: 'lotteryRecord' },
    data
  })
}

// 查询奖品信息
export const getLotteryPrizePool = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/lotteryPrizePool/query',
    params: { requestType: 'lotteryPrizePool' },
    data
  })
}

// 查询 奖品信息 & 抽奖结果 组合数据
export const getPrizePoolAndRecordCompose = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/prizePoolAndRecordCompose/query',
    params: { requestType: 'prizePoolAndRecordCompose' },
    data
  })
}

// 抽奖动作
export const reportLotteryAction = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/lotteryAction/update',
    params: { requestType: 'lotteryAction' },
    data
  })
}
//  ======================  专题抽奖相关 End  ======================

//  ======================  趋势入口 Start  ======================
// 查询趋势入口内容
export const getTrendEntry = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/trendEntry/query',
    params: { requestType: 'trendEntry' },
    data
  })
}
//  ======================  趋势入口 End  ======================

//  ======================  沉浸式组件 Start  ======================
// 查询沉浸式组件内容
export const getImmersiveBanner = (data) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/getImmersiveBanner/query',
    params: { requestType: 'getImmersiveBanner' },
    data
  })
}
//  ======================  沉浸式组件 End  ======================

// 灵活布局组件数据聚合
export const getCccMultiLineCompData = async (data = {}, headers = {}) => {
  if(!Object.values(data).length) return Promise.resolve({
    code: -1,
    msg: 'miss params'
  })

  try {
    return await schttp({
      url: '/api/ccc/multiLine/query',
      method: 'POST',
      data,
      headers
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

export const getCccPurchaseCouponBannerCompData = async (params = {}, headers = {}) => {
  try {
    return await schttp({
      url: '/api/ccc/purchaseCouponBanner/query',
      method: 'POST',
      data: {
        purchaseCouponBannerParams: params
      },
      headers
    })
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}
