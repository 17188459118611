import schttp from 'public/src/services/schttp'
import { getLanguage } from 'public/src/services/api/index.js'
import { RECEIVED_MODE_ENUM, SHOW_TYPE_ENUM, COUPON_REMARK_ENUM } from 'public/src/pages/product_list_v2/components/common/Coupon/common/enum'
import { getSearchDiff } from './utils'
import { souceFormat as couponSouceFormat } from 'public/src/pages/components/coupon/mall/utils.js'
import { isLogin } from 'public/src/pages/common/utils/index.js' 
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { checkSearchCouponNotiOnOneDayNumber, pdsearchCouponTipLocalKey, preSearchCouponTipLocalKey } from 'public/src/pages/product_list_v2/components/common/Coupon/common/index.js'

// 列表券处理逻辑模块

/**
 * 是否在24小时之内
 * @param {*} time 需要判断的时间戳
 * @returns 
 */
const checkWithin24Hours = (time) => {
  if (!time) return false 

  const onDay = 1000 * 24 * 60 * 60
  const now = Date.now()
  return time && now - time < onDay
}

/**
 * 检查 选品 真实列表页券是否在24小时之内显示过，如果显示过则需要剔除
 * @param {String} showCacheKey 用于记录曝光次数的key
 * @param {String[]} cateCouponList 分类券券码列表
 * @param {Number} maxexposelimit 曝光次数
 * @returns {String[]} 剔除24小时之内显示过的券的结果
 */
const filterCouponWithin24Hours = (showCacheKey, cateCouponList = [], maxexposelimit = 0) => {
  let lastShowTime = localStorage.getItem(showCacheKey)
  if (!lastShowTime) {
    return cateCouponList
  }

  lastShowTime = JSON.parse(lastShowTime)

  if ((typeof lastShowTime !== 'object') || Object.keys(lastShowTime).length === 0) return cateCouponList
  return cateCouponList.filter((couponCode) => {
    // 将couponCode转换为全小写 cccx配置的可能存在大小写 但是券后台返回的是全小写 已咨询后端
    couponCode = couponCode.toLowerCase()
    if (!lastShowTime?.[couponCode] || !Array.isArray(lastShowTime[couponCode]) || !lastShowTime[couponCode]?.length) return true

    const displayCount24Hours = lastShowTime[couponCode].filter((time) => {
      return checkWithin24Hours(time)
    })?.length ?? 0

    return displayCount24Hours < maxexposelimit
  })
}

// 选品的cateid是否24小时内在搜索对应的cateid显示过，是则返回true
const checkCateCouponWithin24Hours = (cateId) => {
  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''
  const cateLocalKey = `searchCouponCateIds_${member_id}`


  let cateLocalData = JSON.parse(window.localStorage.getItem(cateLocalKey))

  if (cateLocalData?.[cateId]) {
    return checkWithin24Hours(cateLocalData[cateId])
  }

  return false
}


/**
 * 接口返回的券统一字段处理
 * @param {Object} item 券详情对象
 * @returns 
 */
const transCoupon = (item) => {
  const itemTrans = Object.assign(item, couponSouceFormat(item)) 
  itemTrans.max_three_coupon_rule = itemTrans.coupon_rule?.slice(0, 3) ?? []
  return itemTrans
}

/**
 * 获取3天内即将到期的账号可用券
 * wiki.pageId=1236712064
 */
const getMemberCoupon = async ({
  memberCouponFilterHook
}) => {
  const params = {
    // typeId: 23,                 // 劵包类型劵
    typeIds: [23, 57, 60],      // 普通券包（type id = 23），用增券包（type id =57），下单返券（type id =60）
    type: 1,                    // 1可用券 2不可用券 默认1可用券
    category: 0,                // 0所有券 1全场产品 2部分产品 3运费 默认0所有券
    limit: 20,
    page: 1,
  }
  const memberCoupon = await schttp({
    url: '/api/coupon/memberCoupon/get',
    method: 'POST',
    data: params,
    needLogin: true
  })
  // 可用券
  let memberCouponList = []
  let memberCoupons = memberCoupon?.info?.coupon ?? [] // MOCK.info.coupon
  let isPdSearchPageShow = false
  if (memberCoupons) {
    if (typeof memberCouponFilterHook === 'function') {
      const memberCouponsData = memberCouponFilterHook(memberCoupons)
      memberCoupons = memberCouponsData.coupon
      isPdSearchPageShow = memberCouponsData.isPdSearchPageShow
    }
    const accountCouponTypeIds = [1] // 金额券
    const discountCouponTypeIds = [2, 5] // 折扣券
    const threeDay = 3 * 1000 * 24 * 60 * 60
    memberCouponList = memberCoupons.reduce((arr, item) => {
      const { coupon_type_id, apply_for, end_date } = item
      const isExpired = end_date * 1000 < Date.now()
      const isAccountCoupon = accountCouponTypeIds.includes(+coupon_type_id) && ![5, 6, 9].includes(+apply_for)
      const isDiscountCoupon = discountCouponTypeIds.includes(+coupon_type_id)
      // 筛选出金额券与折扣券
      if (isAccountCoupon || isDiscountCoupon) {
        // 筛选出过期时间小于3天 且未过期
        if (((end_date * 1000 - Date.now() < threeDay) && !isExpired)) {
          arr.push(transCoupon(item))
        }
      }
      return arr
    }, [])
    
    return {
      memberCoupon: memberCoupon,
      couponList: memberCouponList,
      isPdSearchPageShow
    }
  }
}

/**
 * 获取券包以及搜索配置券
 * @param {*} options cateCoupon需要请求的coupon   needCouponPackage是否需要请求券包
 */
const getCouponDetails = async (options = {}) => {

  // const cateCouponCodes = options?.cateCouponCodes ?? []
  // const needCouponPackage = options?.needCouponPackage ?? false
  // const couponPackageFilterHook = options?.couponPackageFilterHook ?? null
  const {
    cateCouponCodes = [],
    needCouponPackage = false,
    couponPackageFilterHook = null
  } = options

  // 请求券包以及搜索配置券
  let sendList = [null, null]
  if (needCouponPackage) {
    // 券包
    sendList[0] = schttp({ url: '/product/getCouponPackage?' + Math.random() })
  }

  // 搜索配置券
  if (cateCouponCodes.length > 0) {
    sendList[1] = schttp({ 
      url: '/api/coupon/getCouponByCouponCodeFilterRule/get',
      params: {
        coupon_codes: cateCouponCodes,
        ignore_check: 1
      }
    })
  }
  

  let [packageCoupon, cateCoupon] = await Promise.all(sendList)

  // 券包券
  let packageCouponList = []
  let packageCouponInfo = packageCoupon?.info?.couponPackage?.coupon
  if (packageCouponInfo?.length) {
    if (typeof couponPackageFilterHook === 'function') {
      packageCouponInfo = couponPackageFilterHook(packageCouponInfo)
    }
    packageCouponList = packageCouponInfo.map(v => transCoupon(v))
    if (!packageCouponList?.length) {
      packageCoupon = null
    }
    
  }

  // 品类券
  let cateCouponList = []
  if (cateCoupon?.info?.list) {
    cateCouponList = cateCoupon?.info?.list?.filter(v => v.userCouponout === '0' ).map(v => {
      if (v.singleAvailableTimes) {
        delete v.singleAvailableTimes
      }

      if (v.times) {
        delete v.times
      }
      
      return transCoupon(v)
    }) ?? []

    cateCoupon.info.list = cateCouponList
  }

  // 搜索券排前面
  return {
    cateCoupon,
    packageCoupon,
    couponList: [...cateCouponList, ...packageCouponList]
  }
}

/**
   * 组装券数据
   */
const assembleCoupon = async ({
  data,
  cateCoupon,
  needCouponPackage,
  needMemberCoupon,
  couponPackageFilterHook,
  memberCouponFilterHook
}) => {
  // 可用券无需拼装
  if (needMemberCoupon) {
    const {
      couponList,
      memberCoupon,
      isPdSearchPageShow
    } = await getMemberCoupon({
      memberCouponFilterHook
    })
    data.originCouponReturn.memberCoupon = memberCoupon ?? {}
    data.couponList = couponList ?? []
    data.isPdSearchPageShow = isPdSearchPageShow
    return
  }
  const couponDetails = await getCouponDetails({
    cateCouponCodes: cateCoupon,
    needCouponPackage,
    needMemberCoupon,
    couponPackageFilterHook
  })
    
  data.originCouponReturn.packageCoupon = couponDetails.packageCoupon ?? {}
  data.originCouponReturn.cateCoupon = couponDetails.cateCoupon ?? {}
  data.couponList = couponDetails.couponList ?? {}
  return data
}


// collectButtonLang: language.SHEIN_KEY_PWA_25884,
// expiresLang: language.SHEIN_KEY_PWA_23547,
// expiresSlotLang: language.SHEIN_KEY_PWA_23548,
// signInLang: language.SHEIN_KEY_PWA_25885,
//           titleLang: language.SHEIN_KEY_PWA_16340,
//           collectLang: language.SHEIN_KEY_PWA_16649,
//           overLang: language.SHEIN_KEY_PWA_25041,
//           couponMaxLang: language.SHEIN_KEY_PWA_18572,
//           offLang: language.SHEIN_KEY_PWA_23808,
//           forOrderOverLang: language.SHEIN_KEY_PWA_15817

/**
 * 
// 列表页券逻辑处理

// 搜索页 >
//     未登录 > 
          abt券坑SearchListCoupon 数字 商品项坑位 
          abt券坑SearchListCoupon up 顶部显示 
          abt券坑SearchListCoupon bottom 底部显示 
            点击登录 > 发放 > 券包 + 分类券 

//     已登录 > abt弹窗SearchSceneCoupon > 分类券

// 选品 真实列表页 >
//     弹窗形式 ListCatgCoupon.p.ListCatgCouponPos === 0
//         已登录  > 直接发放 > 分类券
//         未登录  > 点击登录 > 发放 > 分类券
//     商品流形式 ListCatgCoupon.p.ListCatgCouponPos > 0
//         已登录  > 点击领取 > 分类券
//         未登录  > 点击登录 > 发放 > 分类券

 * @param {*} 
 * @returns 
 */
export const productListCoupons = async ({
  state: { Results },
  getters: { 
    cccConfig,
    listAbtResult: { SearchSceneCoupon, SearchListCoupon, ListCatgCoupon, SearchCouponNoti },
    catInfo,
    sum,
    searchKeywords
  },
  commit
}) => {
  let cateCoupon = cccConfig?.cateCoupon
  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'list/coupon' }) ?? ''
  const { pageName } = catInfo

  const result = {
    Key: 'productListCoupons',
    Abt: {
      SearchSceneCoupon, SearchListCoupon, ListCatgCoupon, SearchCouponNoti
    },
    Data: {}
  }
  // 获取默认值
  const getDefaultResult =  (key = 'productListCoupon') => ({
    // ! 逻辑属性
    pageName,
    key, // 列表券 productListCoupons 搜索券 searchListCoupons 可用券 memberCoupons
    position: -1,
    cateId: '', // 该页面的cateId  搜索页赋值
    receivedMode: '', // login 登陆领取 click 点击领取 
    originCouponReturn: { // 存储返回的券原始数据
      packageCoupon: null,
      cateCoupon: null,
      memberCoupon: null,
    },
    couponList: [],
    // ! UI属性
    showType: '', // 展示形式，弹窗 Modal  流式 Flow  头部 Up  底部 Bottom 流式（可用券）AccountFlow
    // ! 埋点属性
    anaPos: '', // 插坑位置 showType === Flow 时有值
    // ! 杂项
    remark: '', // 标识 只做注释作用
  })

  

  if (sum === 0) return []

  const selectPage = ['page_real_class', 'page_select_class']
  // 是否选品页
  const isSelectPage = selectPage.includes(pageName)
  // 是否搜索页
  const isSearchPage = pageName === 'page_search'

  if (!isSelectPage && !isSearchPage) return result

  const _isLogin = isLogin()
  
  const isRedirectUrl = searchKeywords?.redirectUrl && catInfo?.search_type !== 'store'

  let couponPackageFilterHook = null



  // ! ABT
  // 列表券的定位
  const ListCatgCouponPos = ListCatgCoupon?.p?.ListCatgCouponPos
  // 搜索登录券：券的分支以及定位
  const { SearchSceneCoupon: SearchSceneCouponBranch, SearchSceneCouponPos = false } = SearchSceneCoupon?.p || {}
  // 搜索未登录券：券的定位以及限制
  const { pos: SearchListCouponPos = false, exposelimit: SearchListCouponExposelimit } = SearchListCoupon?.p || {}
  // 账号可用券的定位以及曝光上线
  const { SearchCouponNoti: SearchCouponNotiAbt = '', PreSearchCouponNoti: PreSearchCouponNotiAbt = '' } = SearchCouponNoti?.p || {}
  const [, SearchCouponNotiPos = false, SearchCouponNotiLimit] = SearchCouponNotiAbt.match(/display:(\d+)&max:(\d+)/) || [null, 0, 0]
  const [PreSearchCouponNotiDisplay = false, PreSearchCouponNotiLimit] = PreSearchCouponNotiAbt.match(/display&max:(\d+)/) || [null, 0]
  // ! 修改下面的变量即可 返回时会建立好引用一起返回 注意不要改下列变量的引用地址
  // 列表券
  const listCateCouponResult = getDefaultResult('productListCoupon')
  // 搜索券
  const searchSceneCouponResult = getDefaultResult('searchListCoupon')
  // 可用券
  const memberCouponResult = getDefaultResult('memberCoupon')
  
  // 为需要返回的变量建立引用
  const setResultDataLink = (pos, data) => {
    result.Data[pos] = data
  }

  // 建立引用
  if (isSelectPage) {
    ListCatgCouponPos ? setResultDataLink(ListCatgCouponPos, listCateCouponResult) : setResultDataLink(0, listCateCouponResult)
  } else {
    if (_isLogin) {
      SearchCouponNotiPos && setResultDataLink(SearchCouponNotiPos, memberCouponResult)
      SearchCouponNotiPos && setResultDataLink('preSearch', memberCouponResult) // 单独为preSearch建立引用 上面的key可能会被搜索品类券的key覆盖
      SearchSceneCouponPos ? setResultDataLink(SearchSceneCouponPos, searchSceneCouponResult) : setResultDataLink(0, searchSceneCouponResult) // 同pos的情况下 搜索券会覆盖已有券
    } else {
      SearchListCouponPos ? setResultDataLink(SearchListCouponPos, searchSceneCouponResult) : setResultDataLink(0, searchSceneCouponResult)
    }
  }
 
  // 初始化一遍
  commit('SETCATECOUPON', { result })

  // abt判断 缓存控制
  if (isSelectPage) {
    const showCacheKey = `selectListDisplayedCoupon_${member_id}`
    
    // 选品页需要一张一张券判断，24小时内展示过就剃掉
    const cateCouponNot24Displayed = filterCouponWithin24Hours(showCacheKey, cateCoupon, 1)
    // const ListCatgCouponPos = '0'

    // 选品的cateid是否24小时内在搜索对应的cateid显示过
    const cat_id = Results.cat_info?.cat_id
    if (cat_id && checkCateCouponWithin24Hours(cat_id)) {
      return result
    }

    if ((!ListCatgCouponPos && ListCatgCouponPos !== '0') || !cccConfig.cateCoupon?.length || !cateCouponNot24Displayed?.length) {
      return result
    } else {
      if (ListCatgCouponPos === '0') {
        listCateCouponResult.remark = COUPON_REMARK_ENUM.选品页弹窗券
        listCateCouponResult.showType = SHOW_TYPE_ENUM.Modal
      }  else {
        listCateCouponResult.remark = COUPON_REMARK_ENUM.选品页插坑券
        listCateCouponResult.showType = SHOW_TYPE_ENUM.Flow
        listCateCouponResult.position = ListCatgCouponPos
      }
      listCateCouponResult.receivedMode = RECEIVED_MODE_ENUM.login

      listCateCouponResult.anaPos = ListCatgCouponPos

      cateCoupon = cateCouponNot24Displayed
    }
    await assembleCoupon({
      data: listCateCouponResult,
      cateCoupon,
    })
  } else if (isSearchPage) {
    // 店搜/品牌搜索页没有券 直达词页没有
    if (getSearchDiff(catInfo).hideSearchCoupon || isRedirectUrl) return result
    const showCacheKey = 'searchCouponLastShowTime'
    const cateCouponNot24Displayed = filterCouponWithin24Hours(showCacheKey, cateCoupon, SearchListCouponExposelimit || 1)
    
    cateCoupon = cateCouponNot24Displayed
    searchSceneCouponResult.cateId = Results?.filterAbtParam?.currentId ?? ''
    if (_isLogin) {
      // const { SearchSceneCoupon: SearchSceneCouponBranch, SearchSceneCouponPos: SearchSceneCouponPos } = JSON.parse(localStorage.getItem('MOCKCOUPON')) || { SearchSceneCoupon: 'A', SearchSceneCouponPos: '1' }
      if (SearchSceneCouponBranch && cccConfig.cateCoupon?.length && cateCouponNot24Displayed?.length) {
        // 如果为空/0则弹窗，其余数字则插坑展示
        if (+SearchSceneCouponPos) {
          searchSceneCouponResult.showType = SHOW_TYPE_ENUM.Flow
          searchSceneCouponResult.position = parseInt(SearchSceneCouponPos)
          searchSceneCouponResult.anaPos = searchSceneCouponResult.position
          searchSceneCouponResult.remark = COUPON_REMARK_ENUM.搜索页登录后插坑券
          searchSceneCouponResult.receivedMode = RECEIVED_MODE_ENUM.click
        } else {
          searchSceneCouponResult.showType = SHOW_TYPE_ENUM.Modal
          searchSceneCouponResult.remark = COUPON_REMARK_ENUM.搜索页登录后弹框券
        }
      }
    } else if (!_isLogin) {
      // 过滤24小时超过曝光次数的未登陆搜索券为券包券 券包只能在接口调用完后才能拿到券码
      couponPackageFilterHook = (packageCoupon) => {
        const packageCouponCode = packageCoupon.map(item => item.couponCode)
        const packageCouponNot24Displayed = filterCouponWithin24Hours(showCacheKey, packageCouponCode, SearchListCouponExposelimit || 1)
        packageCoupon = packageCoupon.filter(item => packageCouponNot24Displayed.includes(item.couponCode))
        return packageCoupon
      }
      switch (SearchListCouponPos) {
        case 'up':
          searchSceneCouponResult.remark = COUPON_REMARK_ENUM.搜索页未登录置顶券
          searchSceneCouponResult.showType = SHOW_TYPE_ENUM.Up
          searchSceneCouponResult.anaPos = 'up'
          break
        case 'bottom':
          searchSceneCouponResult.remark = COUPON_REMARK_ENUM.搜索页未登录吸底券
          searchSceneCouponResult.showType = SHOW_TYPE_ENUM.Bottom
          searchSceneCouponResult.anaPos = 'bottom'
          break
        default:
          searchSceneCouponResult.remark = COUPON_REMARK_ENUM.搜索页未登录插坑券
          searchSceneCouponResult.showType = SHOW_TYPE_ENUM.Flow
          searchSceneCouponResult.position = parseInt(SearchListCouponPos)
          searchSceneCouponResult.anaPos = searchSceneCouponResult.position
      }
      searchSceneCouponResult.receivedMode = RECEIVED_MODE_ENUM.login
    }
    await assembleCoupon({
      data: searchSceneCouponResult,
      cateCoupon,
      needCouponPackage: isSearchPage && !_isLogin,
      couponPackageFilterHook
    })

    /* 
      满足以下条件查询可用券接口：
      1: 已登陆
      2: 搜索页展示条件： 
        1）账号可用券abt返回了pos
        2）当搜索品类券有值时 账号可用券的pos须与搜索品类券的pos不同
        3）当搜索品类券没有值时 直接请求
      3: 预搜页展示条件： 预搜页diplay返回1即可
      4: 满足预搜页或者搜索页（2，3）其中一个条件即可！
    */
    const SearchCouponAndMemberCouponPosDifference =  (+SearchCouponNotiPos !== +SearchSceneCouponPos)
    const searchPageDiplay = SearchCouponNotiPos && ((searchSceneCouponResult.couponList?.length && SearchCouponAndMemberCouponPosDifference) || !searchSceneCouponResult.couponList?.length)
    
    if (!searchSceneCouponResult.couponList?.length && SearchCouponNotiPos) {
      // 如果没有搜索券 重新为可用券建立应用
      await setResultDataLink(SearchCouponNotiPos, memberCouponResult)
    }
    const preSearchPageDisplay = PreSearchCouponNotiDisplay
    if (_isLogin && ( searchPageDiplay || preSearchPageDisplay)) {
      memberCouponResult.receivedMode = RECEIVED_MODE_ENUM.notify
      memberCouponResult.remark = COUPON_REMARK_ENUM.搜索页账号内可用券
      memberCouponResult.showType = SHOW_TYPE_ENUM.AccountFlow
      memberCouponResult.position = memberCouponResult.anaPos = parseInt(SearchCouponNotiPos)
      // 此处过滤 搜索结果页页和预搜页的 各自业务侧需要再过滤一遍
      const memberCouponFilterHook = (memberCoupon) => {
        const isPdSearchPageShow = checkSearchCouponNotiOnOneDayNumber(pdsearchCouponTipLocalKey, SearchCouponNotiLimit)
        const isPreSearchPageShow = checkSearchCouponNotiOnOneDayNumber(preSearchCouponTipLocalKey, PreSearchCouponNotiLimit)
        return {
          coupon: isPdSearchPageShow || isPreSearchPageShow ? memberCoupon : [],
          isPdSearchPageShow
        }
      }
      await assembleCoupon({
        data: memberCouponResult,
        needMemberCoupon: true,
        memberCouponFilterHook
      })
    }
  }
  
  // 是否有券，有则需要展示
  // 不展示不请求多语言
  const hasCoupon = Object.keys(result?.Data ?? {}).some(key => {
    return result?.Data[key].couponList?.length > 0
  })

  if (hasCoupon) {
    const languages = await Promise.all([getLanguage('coupon'), getLanguage('coupon_item')])
    if (languages[0]?.language && languages[1]?.language) {
      commit('SETCOUPONLANGUAGE', Object.assign(languages[0]?.language, languages[1]?.language))
    }
  }

  commit('SETCATECOUPON', { result })

  return result
}
